<template>
  <div class="cart">
    <NavHeader    ></NavHeader>
    <div class="content_container">
      <div class="allGood">
        <ul>
          <li>全部商品&nbsp;&nbsp;{{totalGoods}}</li>
        </ul>
      </div>
      <div class="cart_null" v-if="this.shoplist.length == 0">
        <img src="@/assets/images/cart.png" style="width:300px;height:200px;">
      </div>
      <div v-else class="car_list">
        <div class="min_height">
          <div   ref='height'>
            <ul class="first">
              <li><input type="checkbox" :checked="allCheck" @click="all" style="  margin-right:10px">全选</li>
              <li>商品信息</li>
              <li>商品规格</li>
              <li>单价</li>
              <li>数量</li>
              <li>金额</li>
              <li>操作</li>
            </ul>
          </div>
          <ul v-for="(item, index) in shoplist" :key="index" class="list_shop">
            <li>
              <div class="shop_name">
                <p style="width:70px; vertical-align:middle">
                  <input type="checkbox" :checked="item.checked" @click="checkShop(item)">
                </p>
 
                店铺: <span style="color:#999;" @click="$util.toShop(item.shop_type, item.shop_id )" class='hover'>{{item.shop_name}}</span>

              </div>
              <ol v-for="(goods, gIndex) in item.carts_list" :key="gIndex" class="list_product first">
                <li><input type="checkbox" :checked="goods.checked" @click="checkGoods(goods, index)"></li>
                <li class="list-goods-name" 
                @click="$util.toDetail(item.shop_type, item.goods_id)">
                   <img :src="$config.baseUrl + goods.files_path">

                  <p>
                    {{goods.goods_name}}</p>

                </li>
                <li>{{ JSON.parse(goods.option).spec }}</li>
                <li>
                  <strong v-if="JSON.parse(goods.option).id">
                    ￥{{parseFloat(JSON.parse(goods.option).price).toFixed(2)}}
                  </strong>
                  <strong v-else-if="goods.act_status==1">
                    ￥{{goods.act_price}}
                  </strong>
                  <!-- 物料多型号价格 -->
                  <!-- <strong v-if="JSON.parse(goods.option).num_price_data">
                    ￥{{MMprice}}
                  </strong> -->

                  <strong v-else>
                    ￥{{parseFloat(goods.sales_price / 100).toFixed(2)}}
                  </strong>
                </li>
                <li v-if="JSON.parse(goods.option).num_price_data == null">
                  <el-input v-model="goods.quantity" size="small" style="display: flex">
                    <template slot="prepend">
                      <p @click="cart(goods.quantity - 1, goods, false)">-</p>
                    </template>
                    <template slot="append">
                      <p @click="cart(goods.quantity + 1, goods, true)">+</p>
                    </template>
                  </el-input>
                </li>
                <li v-else-if="JSON.parse(goods.option).num_price_data">
                  {{goods.quantity}}
                </li>
                <!-- 物料多型号数量选择 -->
                <!-- <li  v-if="JSON.parse(goods.option).num_price_data">
                  <el-input v-model="quantity1" size="small" style="display: flex" @blur="priceNum2(goods.option,goods.quantity)">
                    <template slot="prepend">
                      <p @click="cart(goods.quantity - 1, goods, false)">-</p>
                    </template>
                    <template slot="append">
                      <p @click="cart(goods.quantity + 1, goods, true)">+</p>
                    </template>
                  </el-input>
                </li> -->
                
                <li>
                  <strong v-if="JSON.parse(goods.option).id" style="color:#ff0036;margin:-4px 0 0 2px;line-height:40px;font-size:16px;">
                    ￥{{parseFloat(JSON.parse(goods.option).price*goods.quantity).toFixed(2)}}
                  </strong>
                  <strong v-else-if="goods.act_status==1" style="color:#ff0036;margin:-4px 0 0 2px;line-height:40px;font-size:16px;">
                    ￥{{(goods.act_price*goods.quantity).toFixed(2)}}
                  </strong>
                  <strong v-else style="color:#ff0036;margin:-4px 0 0 2px;line-height:40px;font-size:16px;">
                    ￥{{parseFloat(goods.sales_price / 100*goods.quantity).toFixed(2)}}
                  </strong>
                </li>
                <li><span @click="delGoods(goods)">删除商品</span></li>
              </ol>
            </li>
          </ul>
        </div>
        <div class="last_tr  " :class="dingwei?'fixd':''">
          <dl>
            <dd> <input type="checkbox" :checked="allCheck" @click="all" style="  margin-right:10px">全选 </dd>
            <dd @click="del">删除</dd>
          </dl>
          <ul>

            <li>
              <span class="tr_p1">共</span>
              <span class="tr_p2"> {{totalQantity}} </span>个
            </li>
            <li>
              <span class="tr_p1">合计：</span>
              <span class="tr_p2">{{totalMoney.toFixed(2)}}</span>
            </li>
            <li class="checked_block" v-if="totalMoney > 0" @click="toAccount">结算</li>
            <li class="checked_none" v-else>结算</li>

          </ul>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      dingwei: false,
      scroll: '',
      shoplist: [],
      allCheck: false,
      quantity1 : 1,
      MMprice: 1,
    };
  },
  components: {
    NavHeader
  },
  watch: {

    "$store.state.cartData"() {
      this.getProuduct();
    }
  },
  computed: {
    totalQantity() {
      let data = this.shoplist;
      let arr = [];
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (goods.checked) {
            arr.push(goods);
          }
        });
      });

      return arr.length;
    },
    totalGoods() {
      let count = 0;
      let data = this.shoplist;
      data.forEach(item => {
        count += item.carts_list.length;
      });
      return count;
    },
    totalMoney() {
      let money = 0;
      let data = this.shoplist;
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (!goods.checked) return;
          if (goods.spec_id) {
            money += JSON.parse(goods.option).price * goods.quantity;
          } else if(goods.act_status==1) {
            money += goods.act_price * goods.quantity;
          } else {
            money += (goods.sales_price / 100) * goods.quantity;
          }
        });
      });

      return money;
    }
  },
  mounted() {
    
    window.addEventListener('scroll', this.scrolls)
    this.$store.dispatch("getCarData").then(()=>{
      this.getProuduct();
    });

    
  },
  methods: {

     //物料多型号产品价格
    // priceNum2(option1,quantity2) {
    //   var priceArr2 = JSON.parse(option1).num_price_data;
    //   console.log(6666,priceArr2)
    //   var pNumArr2 = [];
    //   var numArr3 = [];
    //   for (var i = 0; i < priceArr2.length; i++) {
    //     pNumArr2.push(priceArr2[i].number);
    //     if (Number(priceArr2[i].number) <= Number(quantity2)) 
    //     {
    //       numArr3.push(priceArr2[i].number);
    //     }
    //   }
    //   var pNumFinal2 = Math.min(...pNumArr2);
    //   if (quantity2 < Number(pNumFinal2)) {
    //     this.$message.warning({ message: "数量小于起订量", offset: 300 });
    //     this.quantity1 = Number(pNumFinal2);
    //     return;
    //   }
      
    //   var priceFinal3
    //   var numFinal3 = Math.max(...numArr3)
    //   console.log(numArr3,numFinal3)
    //   priceArr2.forEach(item2=>{
    //     if(item2.number == numFinal3){
    //       priceFinal3 = item2.price
    //     }
    //   })
    //   this.MMprice = Number(priceFinal3)
    // },

    
    scrolls() {
      // console.log(1111,this.$refs)
    let height = this.$refs.height.offsetHeight+110+60+35 ; 
     

      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('height',this.$refs.height.heardh)
      if (this.scroll < height) { this.dingwei = true } else { this.dingwei = false }

    },

   
   
    
    //结算
    toAccount() {
      let that = this;
      if (this.$store.state.member.type == 1) {
        if (this.$store.state.member.status == 0 || this.$store.state.member.status == 3) {
          this.$alert("您还未通过实名认证", {
            confirmButtonText: "去认证"
          }).then(() => {
            this.$router.push({
              path: "/member-setting",
              query: { activeName: 3 }
            });
          });

          return;
        }
        if (this.$store.state.member.status == 1) {
          this.$alert("您的实名认证审核中", {
            confirmButtonText: "确定"
          }).then(() => { });

          return;
        }
      }

      if (this.$store.state.member.type == 2) {
        if (this.$store.state.member.companyStatus == 0 || this.$store.state.member.companyStatus == 3) {
          this.$alert("您还未通过实名认证", {
            confirmButtonText: "去认证"
          }).then(() => {
            this.$router.push({
              path: "/member-setting",
              query: { activeName: 3 }
            });
          });

          return;
        }
        if (this.$store.state.member.companyStatus == 1) {
          this.$alert("您的实名认证审核中", {
            confirmButtonText: "确定"
          }).then(() => { });

          return;
        }
      }

      var selectedList = [];
      
      this.shoplist.forEach(shop => {
        if (shop.checked) {
          selectedList.push(shop);
          return;
        }

        let goods = shop.carts_list.filter(good => good.checked);
        if (goods.length) {
          selectedList.push(Object.assign({}, shop, { carts_list: goods }));
        }
      });
      console.log(9999,this.shoplist,selectedList)
      sessionStorage.setItem("cartDate", JSON.stringify(selectedList));
      this.$router.push("/orderForm");
    },

    // 获取购物车里的产品
    getProuduct() {
      let templist = JSON.parse(JSON.stringify(this.shoplist));
      this.shoplist = this.$store.getters.cartList;

      console.log('shop', this.shoplist)

      this.shoplist.forEach(shop => {
        let oShop = templist.find(a => a.shop_id == shop.shop_id);
        this.$set(shop, "checked", oShop == null ? true : oShop.checked);

        shop.carts_list.forEach(goods => {
          let oGoods = null;
          if (oShop != null) {
            oGoods = oShop.carts_list.find(a => a.goods_id == goods.goods_id);
          }
          this.$set(goods, "checked", oGoods == null ? true : oGoods.checked);
        });

        this.checkAll(shop);
      });
    },

    //批量删除
    del() {
      this.$confirm("确认删除选择的商品吗？", { type: "warning" })
        .then(() => {
          let list = [];
          this.shoplist.forEach(shop => {
            shop.carts_list.forEach(goods => {
              if (goods.checked == true)
                list.push({
                  cart_id: goods.cart_id,
                  goods_id: goods.goods_id
                });
            });
          });

          this.$store.dispatch("delCarData", list);
        })
        .catch(() => { });
    },

    // 删除商品
    delGoods(goods) {
      this.$confirm("确认删除该商品吗？", { type: "warning" })
        .then(() => {
          this.$store.dispatch("delCarData", {
            cart_id: goods.cart_id,
            goods_id: goods.goods_id
          });
        })
        .catch(() => { });
    },

    // 加减数量
    cart(quantity, goods, isPlus) {
      if (!isPlus && quantity === 0) {
        this.delGoods(goods);
        return;
      }

      if (goods.quantity_cart <= 0) {
        this.$toast.text("数量低于范围");
        return;
      }
      if (goods.quantity_cart > goods.quantity) {
        this.$toast.text("数量高于库存");
        return;
      }

      this.$store.dispatch("addCarData", {
        shop_id: goods.goods_shop_id,
        goods_id: goods.goods_id,
        quantity: quantity,
        option: goods.option,
        spec_id: goods.spec_id
      });
    },

    //全选
    all() {
      this.allCheck = !this.allCheck;
      console.log(this.allCheck);
      this.shoplist.forEach(shop => {
        shop.checked = this.allCheck;
        shop.carts_list.forEach(good => {
          good.checked = this.allCheck;
        });
      });
    },

    // 店铺选择
    checkShop(shop) {
      shop.checked = !shop.checked;
      shop.carts_list.forEach(goods => {
        goods.checked = shop.checked;
      });
    },

    // 商品选择
    checkGoods(goods, index) {
      goods.checked = !goods.checked;
      this.checkAll(this.shoplist[index]);
    },

    // 是否全选
    checkAll(shop) {
      // 判断全选中的状态的数目跟商品的数目是否相等
      var checkAll = shop.carts_list.filter(item => {
        return item.checked;
      });
      shop.checked = checkAll.length === shop.carts_list.length;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
@import "@/assets/styles/common";
.min_height{
// min-height:600px;
min-height:calc(100vh - 300px);



}
.fixd {
  position: fixed;
  left: 0;
  margin: 0 auto;
  right: 0;
  bottom: 0;
}
.cart_null {
  text-align: center;
  margin-top: 100px;
}
::v-deep .el-input__inner {
  text-align: center;
  line-height: 35px;
  height: 35px;
}

::v-deep .el-input-group__prepend {
  padding: 0 !important;
  width: 40px;
  height: 35px;

  p {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    width: 40px;
    line-height: 35px;
    height: 35px;
  }
}

::v-deep .el-input-group__append {
  padding: 0 !important;
  width: 40px;
  height: 35px;

  p {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    width: 40px;
    line-height: 35px;
    height: 35px;
  }
}
.first {
  @include space-between;

  width: 100%;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
}

.first li:nth-child(1) {
  width: 70px !important;
}
.first li:nth-child(2) {
  width: 30%;
}
.first li:nth-child(3) {
  width: 15%;
  text-align: center;
}
.first li:nth-child(4) {
  width: 10%;
  text-align: center;
}
.first li:nth-child(5) {
  text-align: center;
  width: 15%;
}
.first li:nth-child(6) {
  text-align: center;
  width: 15%;
}
.first li:nth-child(7) {
  text-align: center;
  flex: 1;
}

.shop_name {
  @include row;
  line-height: 35px;
  height: 35px;
  vertical-align: middle;
}
.Promotion {
  margin-top: 30px;
}
.content_container {
  background: #fff;
  margin-top: 10px !important;
  padding: 20px;
  box-sizing: border-box;
}
.cart {
  margin: 0 auto;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    position: relative;
    margin-left: 5px;
  }

  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
  }

  input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    color: #e50232;
    font-size: 13px;
    font-weight: bold;
  }

  .top {
    height: 95px;
    position: relative;

    // line-height: 95px;
    img {
      height: 45px;
      width: 140px;
      float: left;
      margin-top: 25px;
    }

    h1 {
      float: left;
      font-size: 20px;
      line-height: 95px;
    }

    .Search_Goods {
      margin-left: 590px;
      position: absolute;
      top: 28px;
    }
  }

  .allGood {
    ul {
      height: 35px;
      border-bottom: 2px solid #e6e6e6;

      li {
        height: 100%;
        width: 100px;
        color: #f40;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        border-bottom: 2px solid #f40;
      }
    }
  }

  .list_product {
    width: 100%;
    min-height: 70px;
    @include align-center;
    .list-goods-name {
      @include space-between;
      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border: 1px solid #999;
      }
      p {
        flex: 1;
        line-height: 18px;
      }
    }
  }

  .list_product * {
    display: inline-block;
    vertical-align: middle;
  }

  .list_product li {
    font-size: 10px;
    font-weight: 100;
  }

  .list_product li:nth-child(7) {
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
  }

  .list_product li:nth-child(7):hover {
    color: #ff0036;

    span {
      text-decoration: underline;
    }
  }

  .car_list {
    width: 100%;

    .center_tr {
      height: 130px;
      width: 1188;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      padding: 17px 0 0 0;

      li {
        float: left;
      }
    }

    .center_tr li:nth-child(1) {
      width: 60px;

      input {
        margin: 0 10px 5px 15px;
      }
    }

    .center_tr li:nth-child(2) {
      width: 342px;

      img {
        width: 80px;
        height: 80px;
        float: left;
      }

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
        float: left;
      }
    }

    .center_tr li:nth-child(3) {
      width: 252px;

      p {
        margin-bottom: 5px;

        span {
          font-size: 12px;
        }
      }

      // text-align: center;
      // width: 359px;
      // padding-left: 220px;
      // color: #3c3c3c;
      // font-weight: 700;
      // font-family: Verdana,Tahoma,arial;
    }

    .center_tr li:nth-child(4) {
      text-align: center;
      width: 107px;
      color: #3c3c3c;
      font-weight: 700;
      font-family: Verdana, Tahoma, arial;
    }

    .center_tr li:nth-child(5) {
      text-align: center;
      width: 120px;

      .el-input-number {
        width: 80px;
        // color:#222;
      }
    }

    .center_tr li:nth-child(6) {
      text-align: center;
      color: #f40;
      font-weight: 700;
      width: 138px;
      font-family: Verdana, Tahoma, arial;
      padding-top: 3px;
    }

    .center_tr li:nth-child(7) {
      text-align: center;
      width: 149px;
      padding-left: 30px;

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
      }
    }
  }

  .list_shop {
    min-height: 130px;
    width: 100%;
    box-sizing: border-box;
    background: #f7f7f7;
    margin-bottom: 15px;
    padding: 10px;
    display: inline-block;

    li {
      // float: left;
      // margin-left: 5px;
      // font-size: 16px;
      font-weight: bold;
      // margin-top: 5px;
    }
  }

  .last_tr {
    width: 1226px;
    height: 50px;
    line-height: 50px;
    background: #e5e5e5;
    @include space-between;
    dl {
      @include row;
      vertical-align: middle;

      dd {
        margin-right: 20px;
      }
    }

    ul {
      text-align: right;
      li {
        margin-left: 30px;
        display: inline-block;
        height: 50px;
        line-height: 50px;
      }
    }
    li {
      margin-left: 30px;
      display: inline-block;
      height: 50px;
      line-height: 50px;
    }
  }

  .checked_none {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #b0b0b0;
    cursor: not-allowed;
    color: #fff;
    font-size: 20px;
  }

  .checked_block {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #f22d00;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  .last_tr li {
    height: 100%;

    .tr_p2 {
      width: 150px;
      font-weight: 700;
      font-size: 22px;
      color: #ff0036;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.el-input__inner {
  padding: 0 !important;
  text-indent: 0px !important;
  border-radius: 0 !important;
}

.el-input-number__decrease {
  width: 20px !important;
}

.el-input-number__increase {
  width: 20px !important;
}
</style>
<style lang="scss">
#ELB:hover {
  background-color: red;
  border: 2px solid red;
}

#ELB {
  border-radius: 0;
  background-color: #e94c15;
  border: 2px solid #e94c15;
  color: #fff;
}
